@import url("https://fonts.googleapis.com/css2?family=Moirai+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");

html {
  scroll-snap-type: y mandatory;
}
body {
  margin: 0px;
}

.AppBody {
  overflow-x: hidden;
  display: flex;
  flex: 1 1;
  background-color: white;
}
.AppBody * {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  color: black;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

.landing {
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.landing-inner {
  max-width: 1100px;
  width: 100%;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

canvas {
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
}

.moirai-one-regular {
  font-family: "Moirai One", system-ui;
  font-weight: 400;
  font-style: normal;
}

.teko-Logo {
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.teko-SLOGON {
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.box {
  width: 100vw;
  height: 100vh;
  border-radius: 5px;
  box-shadow: 0 2px 30px rgba(black, 0.2);
  background: lighten(#f0f4c3, 10%);
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.wave {
  opacity: 0.4;
  position: absolute;
  top: 3%;
  left: 50%;
  background: #2dc6ab;
  width: 300vh;
  height: 300vh;
  margin-left: -150vh;
  margin-top: -220vh;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 6000ms infinite linear;
}

.wave.-three {
  animation: drift 8000ms infinite linear;
}

.wave.-two {
  animation: drift 10000ms infinite linear;
  opacity: 0.3;
  background: #a1d1cc;
}

.box:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(#e8a, 1),
    rgba(#def, 0) 80%,
    rgba(white, 0.5)
  );
  z-index: 11;
  transform: translate3d(0, 0, 0);
}

.title {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  line-height: 300px;
  text-align: center;
  transform: translate3d(0, 0, 0);
  color: white;
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
  letter-spacing: 0.4em;
  font-size: 24px;
  text-shadow: 0 1px 0 rgba(black, 0.1);
  text-indent: 0.3em;
}
@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.animated-title {
  font-size: 60px;
  font-family: "Raleway", Sans-serif;
  font-weight: 300;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 100px 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.animated-title .track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 60s linear infinite;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
@media (hover: hover) and (min-width: 700px) {
  .animated-title .content {
    transform: translateY(calc(100% - 8rem));
  }
}

.contentV {
  height: 100vh;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nanum-pen {
  font-family: "Nanum Brush Script", cursive;
  font-weight: 600;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");

body {
  margin: 0px;
}

#container01 {
  filter: url(#threshold) blur(0.6px);
}

#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;

  font-family: "Raleway", sans-serif;
  font-size: 80pt;

  text-align: center;

  -webkit-user-select: none;

     -moz-user-select: none;

          user-select: none;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_aaf875';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_aaf875 {font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875';font-style: normal
}

